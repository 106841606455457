import { LookupMetadataInput, LookupMetadataOutput } from '@mangopay/sdk-payment-methods';
import { useRef, useState } from 'react';
import { trimSpaces } from '@mangopay/checkout-sdk-core';
import { CardPaymentMethodOptions } from '@mangopay/checkout-sdk-elements-core';
import { DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { lookupMetadata } from '../../api/payment-methods/metadata';
import { useGlobalContext } from '../../globalContext';
import { useSentryDebugger } from '../../sentryLogger';

type MetadataState = Record<string, LookupMetadataOutput>;
export const useCardMetadata = () => {
  const { options } = useGlobalContext();
  const metadataCache = useRef<MetadataState>({});
  const [cardMetadata, setCardMetadata] = useState<LookupMetadataOutput>();
  const isLoadingRef = useRef(false);
  const { addBreadcrumb, logError } = useSentryDebugger();
  const cardOptions = options?.paymentMethods?.find((item) => item.type === 'card');
  const isCbCardSupported =
    cardOptions && (cardOptions.options as CardPaymentMethodOptions).supportedCardBrands?.includes('CB');

  const getExistingMetadata = (cardNumber: string): LookupMetadataOutput | null => {
    let existingMetadata = null;
    if (metadataCache.current[cardNumber]) {
      existingMetadata = metadataCache.current[cardNumber];
    } else {
      const foundItem: keyof MetadataState | undefined = Object.keys(metadataCache.current).find((number) =>
        number.startsWith(cardNumber)
      );
      existingMetadata = foundItem ? metadataCache.current[foundItem] : null;
    }
    return existingMetadata;
  };

  const handleFetchPaymentMethodMetadata = async (cardNumber: string) => {
    const requestData: LookupMetadataInput = {
      env: options?.environment || 'SANDBOX',
      bin: cardNumber,
    };
    const response = await lookupMetadata(options!.clientId, requestData);
    if (!response.BinData) {
      throw new Error('Failed to fetch metadata');
    }
    return response;
  };

  const fetchMetadata = async (cardNumber: string) => {
    if (isLoadingRef.current || !isCbCardSupported) {
      return;
    }
    try {
      const cardNumberValue = trimSpaces(cardNumber).slice(0, 8);
      const existingMetadata = getExistingMetadata(cardNumberValue);
      let selectedMetadata: LookupMetadataOutput | null = null;
      if (!existingMetadata) {
        isLoadingRef.current = true;
        setCardMetadata(undefined);
        const response = await handleFetchPaymentMethodMetadata(cardNumberValue);
        selectedMetadata = response;
        addBreadcrumb(DebuggerLogType.BIN_LOOKUP_FETCHED, selectedMetadata);
        isLoadingRef.current = false;
        metadataCache.current = { ...metadataCache.current, [cardNumberValue]: response };
      } else if (existingMetadata) {
        selectedMetadata = existingMetadata;
      }
      setCardMetadata(selectedMetadata as LookupMetadataOutput);
    } catch (e) {
      logError(e as Error);
    }
  };

  return {
    cardMetadata,
    fetchMetadata,
  };
};
