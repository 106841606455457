import { CheckoutSdkEnvironment } from '@mangopay/checkout-sdk-elements-core';
import { WhenThenPaymentMethods } from '@mangopay/sdk-payment-methods';

export const confirmPaypalPayment = async (
  merchanId: string,
  orderId: string,
  environment?: CheckoutSdkEnvironment
) => {
  const methods = new WhenThenPaymentMethods({ environment });

  let response;
  try {
    response = await methods.confirmPaypalPayment(merchanId, orderId);
    return response;
  } catch (error) {
    throw error as Error;
  }
};
