import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import {
  CheckoutContainer,
  CheckoutForm,
  CheckoutFormChangeType,
  CheckoutFormForwardedRef,
  FailedState,
  PAY_BUTTON_MAP,
  SuccessState,
  FullScreenLoader,
} from '@mangopay/checkout-sdk-react-elements';
import { AuthorizePostMessagePayload, CardBrand, getCardBrand, trimSpaces } from '@mangopay/checkout-sdk-core';
import { CheckoutSdkType } from '@mangopay/checkout-sdk-elements-core';
import { CheckoutSdkFrameEventType } from '@mangopay/checkout-sdk-hosted-core';
import {
  useApplePayPaymentComplete,
  useDeactivateSavedCard,
  useLoadPayPalSdkScript,
  usePayPalSdk,
} from '../payment-methods';
import { useGlobalContext } from '../globalContext';
import { useHandlePayment } from './useHandlePayment';
import { getQueryParams } from '../utils/get-query-params';
import { AuthorizeRedirectUrlParamKeys } from '../common/types';
import { useSdkEventsDispatcher } from '../sdk-events-dispatcher';
import { useInitSdk } from './useInitSdk';
import { useCardMetadata } from '../payment-methods/card/useCardMetadata';

export const CheckoutSdk: FunctionComponent = () => {
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const [hostErrorMessage, setHostErrorMessage] = useState<string | undefined>();
  const { sdkType } = getQueryParams();
  const { handleInitialize } = useInitSdk();
  const {
    isLoading,
    options,
    setCheckoutState,
    showSuccessState,
    activePaymentMethod,
    setIsCardFormComplete,
    isCardFormValidationTriggered,
    setIsCardFormValidationTriggered,
    setIsLoading,
  } = useGlobalContext();
  const formRef = useRef<CheckoutFormForwardedRef>(null);
  const { isPaypalSdkScriptLoaded } = useLoadPayPalSdkScript();
  const { render: renderPayPalButton, isPaypalButtonContainerEmpty } = usePayPalSdk();
  const { handlePayment, handlePaymentMethodSelect } = useHandlePayment({ formRef });
  const { cardMetadata, fetchMetadata } = useCardMetadata();
  const { handleAuthorizeApplePayComplete } = useApplePayPaymentComplete();
  const { initSdkEventsDispatcher, dispatchMessageToApp, updateHandlers } = useSdkEventsDispatcher();
  const {
    deactivatingSavedCard,
    setDeactivatingSavedCard,
    deactivatingCardResult,
    deactivatingCardError,
    setDeactivatingCardError,
  } = useDeactivateSavedCard();
  const handleHostAuthComplete = async (payload: AuthorizePostMessagePayload): Promise<void> => {
    const { Id, Key } = payload;
    if (Id && Key === AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey && activePaymentMethod === 'card') {
      await new Promise((resolve) => resolve(true));
    }
  };

  const handleSetLoading = (loading: boolean) => {
    setIsLoading(loading);
  };

  const handleEnable = () => setIsEnabled(true);
  const handleDisable = () => setIsEnabled(false);

  const handleHostSetErrorMessage = (errorMessage?: string): void => {
    if (typeof errorMessage !== 'string') return;
    setHostErrorMessage(errorMessage);
  };

  // we dont need to dispatch sdk events for HPP app
  const handlers = {
    handleInitialize,
    handleEnable,
    handleDisable,
    handleHostAuthComplete,
    handleHostSetErrorMessage,
    handlePayment,
    handleAuthorizeApplePayComplete,
    handleSetLoading,
  };

  updateHandlers(handlers);

  useEffect(() => {
    const unsubscribe = initSdkEventsDispatcher(handlers);
    return unsubscribe;
  }, []);

  const handleChange = useCallback(
    (event: CheckoutFormChangeType) => {
      const {
        value: { savedCard, card },
      } = event;
      let cardBrand: CardBrand = 'OTHER';
      setCheckoutState(event);
      if (hostErrorMessage) {
        setHostErrorMessage(undefined);
      }
      if (savedCard) {
        cardBrand = savedCard.CardProvider as CardBrand;
      } else if (card) {
        cardBrand = getCardBrand(card.cardNumber || '');
        if (card.cardNumber && trimSpaces(card.cardNumber).length >= 8) {
          fetchMetadata(card.cardNumber);
        }
      }

      dispatchMessageToApp(CheckoutSdkFrameEventType.Change, {
        isComplete: event.isComplete,
        value: {
          cardBrand,
        },
        errors: event.errors,
      });
    },
    [setHostErrorMessage, fetchMetadata]
  );

  const handleDeactivateSavedCard = (cardId: string) => {
    setDeactivatingSavedCard(true);
    dispatchMessageToApp(CheckoutSdkFrameEventType.DeactivateSavedCard, { cardId });
  };

  const PayButton = PAY_BUTTON_MAP[activePaymentMethod] || PAY_BUTTON_MAP.card;
  const isFormDisabled = !isEnabled || isLoading;

  useEffect(() => {
    const isEmpty = isPaypalButtonContainerEmpty();
    if (activePaymentMethod === 'paypal' && isPaypalSdkScriptLoaded && isEmpty) {
      (async function render() {
        await renderPayPalButton();
      })();
    }
  }, [isPaypalSdkScriptLoaded, activePaymentMethod, renderPayPalButton]);
  // const canPayWithCard = (isEnabled && checkoutState?.isComplete) ?? false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const payButtonIsDisabled = (activePaymentMethod === 'card' && !canPayWithCard) || isLoading || !isEnabled;
  return (
    <>
      {options && (
        <CheckoutContainer
          theme={options.branding}
          language={options.locale}
          amount={options.amount.value}
          currency={options.amount.currency}
        >
          <CheckoutForm
            ref={formRef}
            cardMetadata={cardMetadata}
            disabled={isFormDisabled}
            paymentMethods={options.paymentMethods}
            onChange={handleChange}
            onPaymentMethodSelect={handlePaymentMethodSelect}
            onDeactivateSavedCard={handleDeactivateSavedCard}
            deactivatingSavedCard={deactivatingSavedCard}
            loading={isLoading}
            activePaymentMethod={activePaymentMethod}
            setIsCardFormComplete={setIsCardFormComplete}
            isCardFormValidationTriggered={isCardFormValidationTriggered}
            setIsCardFormValidationTriggered={setIsCardFormValidationTriggered}
            deactivatingCardResult={deactivatingCardResult}
            deactivatingCardError={deactivatingCardError}
            setDeactivatingCardError={setDeactivatingCardError}
          />
          {sdkType === CheckoutSdkType.Checkout ? <PayButton handlePay={handlePayment} /> : null}
          <FullScreenLoader isVisible={isLoading} />

          <FailedState showFailedState={Boolean(hostErrorMessage)} label={hostErrorMessage} />
          <SuccessState showSuccessState={showSuccessState} />
        </CheckoutContainer>
      )}
    </>
  );
};
