import { useEffect, useCallback, useState } from 'react';
import { CheckoutSdkFrameEventType } from '@mangopay/checkout-sdk-hosted-core';
import { createScriptElement, TypedError } from '@mangopay/checkout-sdk-core';
import { CheckoutSdkEnvironment, PaypalPaymentMethodOptions } from '@mangopay/checkout-sdk-elements-core';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';
import { useGlobalContext } from '../../globalContext';
import {
  PAYPAL_SDK_SCRIPT_ID,
  LOAD_PAYPAL_SDK_SCRIPT_ERROR_MESSAGE,
  PAYPAL_SDK_SCRIPT_SRC,
  PAYPAL_PARTNER_ID,
} from './consts';

interface UseLoadPaypalSdkScriptOutput {
  isPaypalSdkScriptLoaded: boolean;
}

export const useLoadPayPalSdkScript = (): UseLoadPaypalSdkScriptOutput => {
  const { options } = useGlobalContext();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const [isPaypalSdkScriptLoaded, setIsPaypalSdkScriptLoaded] = useState(false);

  const paypalOptions = options?.paymentMethods?.find((option) => option?.type === 'paypal')
    ?.options as PaypalPaymentMethodOptions;

  const onScriptLoad = useCallback(() => {
    setIsPaypalSdkScriptLoaded(true);
  }, []);

  const onScriptError = useCallback(() => {
    const error: TypedError = {
      Status: 'ERROR',
      ResultMessage: LOAD_PAYPAL_SDK_SCRIPT_ERROR_MESSAGE,
    };
    dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
  }, []);

  useEffect(() => {
    if (!paypalOptions || isPaypalSdkScriptLoaded || !paypalOptions.merchantId) return undefined;

    const clientId =
      options?.environment === CheckoutSdkEnvironment.PRODUCTION
        ? process.env.REACT_APP_PAYPAL_PROD_CLIENT_ID
        : process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID;

    const includeBuyerCountry = options?.environment === CheckoutSdkEnvironment.SANDBOX ? '&buyer-country=FR' : '';

    const paypalSrc = `${PAYPAL_SDK_SCRIPT_SRC}&client-id=${clientId ?? ''}&merchant-id=${
      paypalOptions.merchantId
    }&currency=${options?.amount.currency}${includeBuyerCountry}`;

    const { element, removeElementFn } = createScriptElement({
      src: paypalSrc,
      id: PAYPAL_SDK_SCRIPT_ID,
      crossOrigin: 'anonymous',
      onScriptLoad,
      onScriptError,
    });

    element.setAttribute('data-partner-attribution-id', PAYPAL_PARTNER_ID);

    return () => {
      removeElementFn();
    };
  }, [isPaypalSdkScriptLoaded, paypalOptions, options?.amount.currency]);

  return { isPaypalSdkScriptLoaded };
};

export default useLoadPayPalSdkScript;
