import { CardBrand } from './card-brand';

export const getCardBrand = (cardNumber: string): CardBrand => {
  if (RegExp('^4,*').test(cardNumber)) {
    return 'VISA';
  }

  if (RegExp('^5(1|2|3|4|5),*').test(cardNumber) || RegExp('^2,*').test(cardNumber)) {
    return 'MASTERCARD';
  }

  if (RegExp('^3(4|7),*').test(cardNumber)) {
    return 'AMEX';
  }

  if (RegExp('^5(0|6|7|8),*').test(cardNumber) || RegExp('^6,*').test(cardNumber)) {
    return 'MAESTRO';
  }

  return 'OTHER';
};
