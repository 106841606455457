/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useRef, useEffect } from 'react';
import { CheckoutSdkFrameEventType, CheckoutSdkHostEventType } from '@mangopay/checkout-sdk-hosted-core';
import { CreatePaypalPayInResult } from '@mangopay/sdk-payment-methods';
import { PayPalOnApproveData, PayPalButtonsComponent, TypedError } from '@mangopay/checkout-sdk-core';
import { PaypalPaymentMethodOptions } from '@mangopay/checkout-sdk-elements-core';
import { CreatePaypalPaymentCompleteEvent } from './types';
import { usePaypalPaymentComplete } from './usePaypalPaymentComplete';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';
import { useGlobalContext } from '../../globalContext';
import { confirmPaypalPayment } from '../../api/payment-methods/paypal/confirm-paypal-payment';

export interface UsePaypalSdkResult {
  render: () => Promise<void>;
  isPaypalButtonContainerEmpty: () => boolean | null;
}

export const usePayPalSdk = (): UsePaypalSdkResult => {
  const [createPaypalPayInResult, setCreatePaypalPayInResult] = useState<null | CreatePaypalPayInResult | TypedError>(
    null
  );
  const createPaypalPayInResultRef = useRef(createPaypalPayInResult);
  const { options } = useGlobalContext();
  const paypalOptions = options?.paymentMethods?.find((option) => option?.type === 'paypal')
    ?.options as PaypalPaymentMethodOptions;

  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const { handleCreatePaypalPayment, isCreatePaypalPaymentSucceeded, tokenizationComplete } =
    usePaypalPaymentComplete();

  useEffect(() => {
    createPaypalPayInResultRef.current = createPaypalPayInResult;
  }, [createPaypalPayInResult]);

  const render = async () => {
    const createOrder = () => {
      dispatchMessageToApp(CheckoutSdkFrameEventType.CreatePaypalPayment, {});

      return new Promise<unknown>((resolve, reject) => {
        const handler = (e: MessageEvent<CreatePaypalPaymentCompleteEvent>) => {
          const { data, eventType } = e.data;
          if (eventType === CheckoutSdkHostEventType.CreatePaypalPaymentComplete) {
            if (isCreatePaypalPaymentSucceeded(data)) {
              setCreatePaypalPayInResult(data);
              resolve(data.PaypalOrderID);
            } else {
              reject(data);
            }
            window.removeEventListener('message', handler);
          }
        };

        window.addEventListener('message', handler);
      });
    };

    const onApprove = async (data: PayPalOnApproveData) => {
      tokenizationComplete(data);

      if (paypalOptions?.merchantId && data.orderID) {
        const details = await confirmPaypalPayment(paypalOptions?.merchantId, data.orderID, options?.environment);

        dispatchMessageToApp(CheckoutSdkFrameEventType.PaymentComplete, {
          ...createPaypalPayInResultRef.current,
          Status: details?.transactionStatus,
        });
      } else {
        console.error('Merchant ID is missing.');
      }
    };

    const onCancel = () => {
      dispatchMessageToApp(CheckoutSdkFrameEventType.Cancel, undefined);
    };

    const paypalButtons: PayPalButtonsComponent | undefined = window?.paypal.Buttons?.({
      createOrder,
      onApprove,
      onCancel,
      onError: (error: CreatePaypalPayInResult) => {
        handleCreatePaypalPayment(error);
      },
      style: paypalOptions.style,
    });

    if (paypalButtons) {
      await paypalButtons.render('#paypal-button-container');
    } else {
      throw new Error('PayPal Buttons could not be initialized.');
    }
  };

  function isPaypalButtonContainerEmpty() {
    const container = document.querySelector('#paypal-button-container');

    if (!container) {
      return null;
    }

    return container.children.length === 0;
  }

  return {
    render,
    isPaypalButtonContainerEmpty,
  };
};
