import { useEffect, useState } from 'react';
import { CheckoutSdkHostEventType, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { DeactivateSavedCardResult } from '@mangopay/sdk-payment-methods';
import { DeactivateSavedCardErrorEvent, DeactivateSavedCardMessageEvent } from './types';

interface UseDeactivateSavedCardReturn {
  deactivatingSavedCard: boolean;
  setDeactivatingSavedCard: React.Dispatch<React.SetStateAction<boolean>>;
  setDeactivatingCardError: React.Dispatch<React.SetStateAction<boolean>>;
  deactivatingCardResult: DeactivateSavedCardResult | null;
  deactivatingCardError: boolean;
}

export const useDeactivateSavedCard = (): UseDeactivateSavedCardReturn => {
  const [deactivatingSavedCard, setDeactivatingSavedCard] = useState(false);
  const [deactivatingCardResult, setDeactivatingCardResult] = useState<DeactivateSavedCardResult | null>(null);
  const [deactivatingCardError, setDeactivatingCardError] = useState(false);

  useEffect(() => {
    const handleMessage = (e: MessageEvent<DeactivateSavedCardMessageEvent>) => {
      const { eventType, data } = e.data;
      if (eventType === CheckoutSdkHostEventType.DeactivateSavedCardComplete) {
        setDeactivatingSavedCard(false);
        setDeactivatingCardResult(data);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    const handleError = (e: MessageEvent<DeactivateSavedCardErrorEvent>) => {
      const { eventType, data } = e.data;
      if (
        eventType === CheckoutSdkHostEventType.LogHostError &&
        data.type === DebuggerLogType.DEACTIVATE_SAVED_CARD_ERROR
      ) {
        setDeactivatingSavedCard(false);
        setDeactivatingCardError(true);
      }
    };

    window.addEventListener('message', handleError);

    return () => {
      window.removeEventListener('message', handleError);
    };
  }, []);

  return {
    deactivatingSavedCard,
    setDeactivatingSavedCard,
    deactivatingCardResult,
    deactivatingCardError,
    setDeactivatingCardError,
  };
};
